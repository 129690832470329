import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { Link, Text } from "ui";
import { blue400, grey300, yellow400 } from "ui/colors";
import { TRANSITION_NORMAL } from "uiKit/theme/transitions";
import { desktop, homepageMenuTablet } from "uiKit/theme/mediaQueries";
import { IconAngleDown, IconArrowRight } from "ui/icons";

import LogoSvg from "./components/LogoSvg";
import MenuButton from "./components/MenuButton";
import ProductMenu from "./components/ProductMenu";
import ExploreMenu from "./components/ExploreMenu";
import LearnMenu from "./components/LearnMenu";
import { spacing25 } from "ui/spacing";

type Props = {
    templateCode?: string | null;
};

const PRODUCT_MENU = "product";
const EXPLORE_MENU = "explore";
const LEARN_MENU = "learn";

type DropdownStates =
    | typeof PRODUCT_MENU
    | typeof LEARN_MENU
    | typeof EXPLORE_MENU
    | null;

export default function Header(props: Props) {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState<DropdownStates>(null);

    const templateCode = props.templateCode;
    const querystring = new URLSearchParams();
    if (templateCode) {
        querystring.set("templateCode", templateCode);
    }
    const signupUrl = templateCode
        ? `/signup?${querystring.toString()}`
        : "/signup";

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [isMobileMenuOpen]);

    const ldClient = useLDClient();
    const LDFlagShowAboutUsPage =
        ldClient?.allFlags()["expr86ctuvxw3-showAboutUsPage"];

    return (
        <Root>
            <HeaderContent>
                <Link href="/" inheritColor suppressHoverState>
                    <Logo />
                </Link>
                <Nav
                    isMobileMenuOpen={isMobileMenuOpen}
                    onMouseLeave={() =>
                        isMobileMenuOpen || setDropdownOpen(null)
                    }
                >
                    <DropdownButton
                        isOpen={isDropdownOpen === PRODUCT_MENU}
                        onClick={() => {
                            setDropdownOpen(
                                isDropdownOpen === PRODUCT_MENU
                                    ? null
                                    : PRODUCT_MENU,
                            );
                        }}
                    >
                        <Text>Product</Text> &nbsp;
                        <IconAngleDown />
                    </DropdownButton>
                    <Menu animate={isDropdownOpen === PRODUCT_MENU}>
                        <ProductMenu />
                    </Menu>
                    <DropdownButton
                        isOpen={isDropdownOpen === EXPLORE_MENU}
                        onClick={() => {
                            setDropdownOpen(
                                isDropdownOpen === EXPLORE_MENU
                                    ? null
                                    : EXPLORE_MENU,
                            );
                        }}
                    >
                        <Text>Explore</Text> &nbsp;
                        <IconAngleDown />
                    </DropdownButton>
                    <Menu animate={isDropdownOpen === EXPLORE_MENU}>
                        <ExploreMenu />
                    </Menu>
                    <DropdownButton
                        isOpen={isDropdownOpen === LEARN_MENU}
                        onClick={() => {
                            setDropdownOpen(
                                isDropdownOpen === LEARN_MENU
                                    ? null
                                    : LEARN_MENU,
                            );
                        }}
                    >
                        <Text>Learn</Text> &nbsp;
                        <IconAngleDown />
                    </DropdownButton>
                    <Menu animate={isDropdownOpen === LEARN_MENU}>
                        <LearnMenu />
                    </Menu>
                    <Link href="/pricing" inheritColor>
                        <Text>Pricing</Text>
                    </Link>
                    {LDFlagShowAboutUsPage && (
                        <Link
                            href="https://get.clearcalcs.com/about"
                            inheritColor
                        >
                            <Text>About Us</Text>
                        </Link>
                    )}
                    <LoginLink href="/login" inheritColor dataCy="login-link">
                        <Text>Log in</Text>
                    </LoginLink>
                    <SignUpLink href={signupUrl} inheritColor>
                        <Text>Try for Free</Text> &nbsp;
                        <IconArrowRight />
                    </SignUpLink>
                </Nav>
                <HeaderMenuButton
                    onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                    active={isMobileMenuOpen}
                />
            </HeaderContent>
        </Root>
    );
}

const Root = styled.header`
    height: 5em;
    position: sticky;
    top: 0;
    z-index: 10;

    background-color: white;
    color: black;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
`;

const HeaderContent = styled.div`
    max-width: 1200px;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
    padding: 0 20px;
`;

const Logo = styled(LogoSvg)`
    height: 2em;
    color: ${blue400};
`;

const Nav = styled.nav<{ isMobileMenuOpen: boolean }>`
    width: 100%;
    height: inherit;
    display: flex;
    gap: 28px;
    align-items: center;
    margin-left: 28px;

    ${homepageMenuTablet`
        height: calc(100vh - 5em);
        
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        overflow-y: scroll; 

        background-color: white;
        margin-left: 0;
        padding: 0 20px 120px;

        transition: opacity ${TRANSITION_NORMAL}, visibility ${TRANSITION_NORMAL};
        opacity: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? 1 : 0)};
        visibility: ${({ isMobileMenuOpen }) =>
            isMobileMenuOpen ? "visible" : "hidden"};

        > * {
            display: block;
            padding: 2.25em 0;
            border-bottom: 1px solid ${grey300};
        }
    `}
`;

const DropdownButton = styled.button<{ isOpen?: boolean }>`
    width: auto;
    height: inherit;
    position: relative;

    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-underline-offset: ${spacing25};

    transition: text-decoration 0.5s;

    // Using after so the text stays aligned and doesn't jump with
    // border changes.
    &:after {
        width: 100%;
        height: 5px;
        display: block;
        position: absolute;
        bottom: 0;
        background: ${({ isOpen }) =>
            isOpen ? `${yellow400}` : "transparent"};
        content: " ";
    }

    // Icon
    > :last-child {
        transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "none")};
        transition: transform ${TRANSITION_NORMAL};
    }

    ${homepageMenuTablet`
        width: 100%;
        height: auto;
        
        &:after {
            display: none;
        }
    `}

    ${desktop`
        &:hover {
            text-decoration-color: inherit;
        }
    `}
`;

const Menu = styled.div<{ animate: boolean }>`
    width: 100%;
    position: absolute;
    top: 5em;
    left: 0;

    // Negative index is required to drop menu behind the root element
    // so the shadow show correct, as the menu is a child of root.
    z-index: -1;

    transition-property: opacity, visibility, transform;
    transition-duration: ${TRANSITION_NORMAL};

    box-shadow: 0 70vh 50px 60vh rgb(0, 0, 0, 0.5);

    opacity: ${({ animate }) => (animate ? 1 : 0)};
    visibility: ${({ animate }) => (animate ? "visible" : "hidden")};
    transform: ${({ animate }) =>
        animate ? "translateY(0px)" : "translateY(-20px)"};

    background-color: white;

    & > * {
        max-width: 1200px;
        height: auto;
        position: relative;
        margin: 0 auto;
        padding: 20px;
    }

    ${homepageMenuTablet`
        height: auto;
        display: ${({ animate }) => (animate ? "block" : "none")};
        position: relative;
        top: 0;
        z-index: auto;
        box-shadow: none;
        padding: 0;
        & > * {
            padding: 36px 0;
        }
    `}
`;

const HeaderMenuButton = styled(MenuButton)`
    display: none;

    ${homepageMenuTablet`
        display: block;
    `}
`;

const AuthLink = styled(Link)`
    border-radius: 10px;
    text-align: center;

    ${homepageMenuTablet`
        padding: 1.25em;
        margin-top: 1.25em;
        border: 1px solid;
    `}
`;

const SignUpLink = styled(AuthLink)`
    padding: 0.75em 1.25em;
    background-color: ${blue400};
    color: white;

    ${homepageMenuTablet`
        // Force overide the padding from SignupLink in Mobile view,
        // so all the buttons have the same height, this was causing
        // issues with specificity over the media query in AuthLink.
        //
        // Consistently broken in Brave.
        padding: 1.25em;
    `}
`;

const LoginLink = styled(AuthLink)`
    margin-left: auto;
`;
