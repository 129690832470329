import React from "react";
import styled from "styled-components";

import { Link, Text, Paragraph } from "ui";
import { homepageMenuTablet } from "uiKit/theme/mediaQueries";
import { grey300 } from "ui/colors";

import {
    IconCalculator,
    IconRuler,
    IconHammer,
    IconHandsHelping,
    IconIndustry,
    IconClipboard,
    IconHome,
    IconBuilding,
    IconStore,
    IconStoreAlt,
    IconHouzz,
} from "ui/icons";

import ListItemContainer from "./ListItemContainer";

export default function ExploreMenu() {
    return (
        <DropdownSplitContainer>
            <LeftContainerWithDivider>
                <Paragraph>By Role</Paragraph>
                <DropdownList>
                    <Link
                        href="/persona/structural-engineer"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconCalculator />
                            <Text bold>Structural Engineer</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/persona/architect"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconRuler />
                            <Text bold>Architect</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/persona/building-designer"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconHammer />
                            <Text bold>Building Designer</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/persona/plan-reviewer"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer noMargin>
                            <IconClipboard />
                            <Text bold>Plan Reviewer</Text>
                        </ListItemContainer>
                    </Link>
                </DropdownList>
            </LeftContainerWithDivider>
            <MidContainerWithDivider>
                <Paragraph>By Use Case</Paragraph>
                <DropdownList>
                    <Link
                        href="/use-case/residential-design"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconHome />
                            <Text bold>Residential</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/use-case/structural-design"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconBuilding />
                            <Text bold>Structural</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/use-case/commercial"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconStore />
                            <Text bold>Commercial</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/use-case/light-commercial"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconStoreAlt />
                            <Text bold>Light Commercial</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/use-case/industrial"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer noMargin>
                            <IconHouzz />
                            <Text bold>Industrial</Text>
                        </ListItemContainer>
                    </Link>
                </DropdownList>
            </MidContainerWithDivider>
            <RightContainerWithDivider>
                <Paragraph>ClearCalcs for</Paragraph>
                <DropdownList>
                    <Link
                        href="/persona/partnerships"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer>
                            <IconHandsHelping />
                            <Text bold>Partners</Text>
                        </ListItemContainer>
                    </Link>
                    <Link
                        href="/persona/manufacturers"
                        inheritColor
                        suppressHoverState
                    >
                        <ListItemContainer noMargin>
                            <IconIndustry />
                            <Text bold>Manufacturers</Text>
                        </ListItemContainer>
                    </Link>
                </DropdownList>
            </RightContainerWithDivider>
        </DropdownSplitContainer>
    );
}

// The use of :first-child here is to create the horizontal grey divider on mobile
const DropdownSplitContainer = styled.div`
    display: flex;
    gap: 20px;

    ${homepageMenuTablet`
        display: block;
    `}
`;

const LeftContainerWithDivider = styled.div`
    border-right: 1px solid ${grey300};
    width: 35%;

    & > p {
        margin-bottom: 20px;
    }

    ${homepageMenuTablet`
        width: 100%;
        margin-bottom: 36px;
        padding: 0;    
        border: 0;
    `}
`;

const MidContainerWithDivider = styled.div`
    border-right: 1px solid ${grey300};
    width: 30%;

    & > p {
        margin-bottom: 20px;
    }

    ${homepageMenuTablet`
        width: 100%;
        margin-bottom: 36px;
        padding: 0;    
        border: 0;
    `}
`;

const RightContainerWithDivider = styled.div`
    width: 35%;

    & > p {
        margin-bottom: 20px;
    }

    ${homepageMenuTablet`
        width: 100%;
        padding: 0;    
        border: 0;
    `}
`;

const DropdownList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    ${homepageMenuTablet`
        display: block;
    `}
`;
