import React from "react";
import styled, { css } from "styled-components";

type Props = {
    className?: string;
    onClick: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    active: boolean;
};

const MenuButton = (props: Props) => (
    <Button
        onClick={props.onClick}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        className={props.className}
        type="button"
    >
        <ContentBox>
            <Hamburger isActive={props.active} />
        </ContentBox>
    </Button>
);

export default MenuButton;

const Button = styled.button`
    width: 2.75em;
    height: 2.75em;
`;

const ContentBox = styled.span`
    width: 1.5em;
    height: 1.5em;
    display: block;
    margin-left: auto;
`;
const Hamburger = styled.span<{ isActive: boolean }>`
    display: block;
    top: 50%;

    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &:before,
    &:after {
        width: inherit;
        position: absolute;
        display: block;
        content: "";

        height: 2px;
        border-radius: 2px;

        background-color: currentColor;

        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &:before {
        top: -10px;
        transition:
            top 0.1s 0.25s ease-in,
            opacity 0.1s ease-in;
    }
    &:after {
        bottom: -10px;
        transition:
            bottom 0.1s 0.25s ease-in,
            transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    ${(props) => {
        return props.isActive
            ? css`
                  transform: rotate(225deg);
                  transition-delay: 0.12s;
                  transition-timing-function: cubic-bezier(
                      0.215,
                      0.61,
                      0.355,
                      1
                  );

                  &:before {
                      top: 0;
                      opacity: 0;
                      transition:
                          top 0.1s ease-out,
                          opacity 0.1s 0.12s ease-out;
                  }

                  &:after {
                      bottom: 0;
                      transform: rotate(-90deg);
                      transition:
                          bottom 0.1s ease-out,
                          transform 0.22s 0.12s
                              cubic-bezier(0.215, 0.61, 0.355, 1);
                  }
              `
            : "";
    }};
`;
