import React, { useEffect } from "react";
import Head from "next/head";

import segment from "segment";
import opengraphSrc from "marketing/images/opengraphdefault.png";

const DEFAULT_DESCRIPTION =
    "Online structural design software. Calculation tools for shear force, bending moment, cantilever deflection, steel beam calculations, wood, concrete and more.";

type IProps = {
    title: false | string;
    description?: string;
    canonicalHref: string;
    image?: string;

    children?: React.ReactNode;
} & (
    | { noTrack?: true; trackInMixpanel?: never }
    | { noTrack?: never; trackInMixpanel?: true }
);

function formatTitle(value) {
    return value
        ? `${value} | ClearCalcs`
        : "ClearCalcs | Structural Design Software For Beam Calculations, Shear, Moment & More";
}

const SEO = (props: IProps) => {
    const canonicalURL = `https://clearcalcs.com${props.canonicalHref}`;

    useEffect(() => {
        if (!!props.noTrack) return;

        const properties = {
            title: props.title === false ? "Homepage" : props.title,
            url: canonicalURL,
        };
        const options = {
            integrations: {
                Mixpanel: !!props.trackInMixpanel,
            },
        };
        if (segment.instance) {
            segment.instance.page(props.title, properties, options);
        } else {
            console.info("page:", props.title, properties, options);
        }
    }, [props.title, canonicalURL, props.trackInMixpanel, props.noTrack]);

    return (
        <Head>
            <title>{formatTitle(props.title)}</title>
            <meta
                name="description"
                content={props.description || DEFAULT_DESCRIPTION}
            />
            <link rel="canonical" href={canonicalURL} key="canonical" />

            <meta
                property="og:title"
                content={
                    props.title ? `${props.title} | ClearCalcs` : "ClearCalcs"
                }
                key="og:title"
            />
            <meta
                property="og:description"
                content={props.description || DEFAULT_DESCRIPTION}
                key="og:description"
            />
            <meta property="og:url" content={canonicalURL} key="og:url" />
            <meta
                property="og:image"
                content={
                    props.image || `https://clearcalcs.com${opengraphSrc.src}`
                }
                key="og:image"
            />

            {props.children}
        </Head>
    );
};
export default SEO;

export function PageTitle({ value }) {
    return (
        <Head>
            <title>{formatTitle(value)}</title>
        </Head>
    );
}
