import React from "react";
import styled from "styled-components";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { Link, Paragraph } from "ui";
import { blue700 } from "ui/colors";
import {
    mobile,
    homepageMenuTablet,
    landscapeTablet,
} from "uiKit/theme/mediaQueries";
import { IconYoutube, IconFacebook, IconLinkedin, IconTwitter } from "ui/icons";

import LogoSvg from "header/components/LogoSvg";

export default function Footer() {
    const ldClient = useLDClient();
    const LDFlagShowAboutUsPage =
        ldClient?.allFlags()["expr86ctuvxw3-showAboutUsPage"];

    return (
        <Root>
            <ContentWrapper>
                <LogoWrapper>
                    <Link href="/" inheritColor suppressHoverState>
                        <Logo />
                    </Link>
                </LogoWrapper>
                <LinksContainer>
                    <LinksColumn>
                        <Paragraph bold>Product</Paragraph>
                        <Link href="/calculations" inheritColor>
                            Calculations
                        </Link>
                        <Link href="/standards" inheritColor>
                            Standards
                        </Link>
                        <Link href="/freetools" inheritColor>
                            Free Tools
                        </Link>
                        <Link href="/features" inheritColor>
                            Why ClearCalcs?
                        </Link>
                        <Link href="/pricing" inheritColor>
                            Pricing
                        </Link>
                        <Link
                            href="https://clearcalcs.com/support/about-clearcalcs/our-quality-and-transparency-promise"
                            inheritColor
                        >
                            Our Quality Promise
                        </Link>
                    </LinksColumn>
                    <LinksColumn>
                        <Paragraph bold>Explore by Role</Paragraph>
                        <Link href="/persona/structural-engineer" inheritColor>
                            Structural Engineer
                        </Link>
                        <Link href="/persona/architect" inheritColor>
                            Architect
                        </Link>
                        <Link href="/persona/building-designer" inheritColor>
                            Building Designer
                        </Link>
                        <Link href="/persona/plan-reviewer" inheritColor>
                            Plan Reviewer
                        </Link>
                        <br />
                        <Paragraph bold>Explore by Use Case</Paragraph>
                        <Link href="/use-case/residential-design" inheritColor>
                            Residential
                        </Link>
                        <Link href="/use-case/structural-design" inheritColor>
                            Structural
                        </Link>
                        <Link href="/use-case/commercial" inheritColor>
                            Commercial
                        </Link>
                        <Link href="/use-case/light-commercial" inheritColor>
                            Light Commercial
                        </Link>
                        <Link href="/use-case/industrial" inheritColor>
                            Industrial
                        </Link>
                        <br />
                        <Paragraph bold>ClearCalcs For</Paragraph>
                        <Link href="/persona/partnerships" inheritColor>
                            Partners
                        </Link>
                        <Link href="/persona/manufacturers" inheritColor>
                            Manufacturers
                        </Link>
                    </LinksColumn>
                    <LinksColumn>
                        <Paragraph bold>Learn</Paragraph>
                        <Link href="/customers" inheritColor>
                            Case Studies
                        </Link>
                        <Link href="/webinars" inheritColor>
                            Webinars
                        </Link>
                        <Link href="/videos" inheritColor>
                            Videos
                        </Link>
                        <Link href="/blog" inheritColor>
                            Blog
                        </Link>
                        <Link href="/support" inheritColor>
                            Help Centre
                        </Link>
                        <Link
                            href="/the-best-strucalc-alternative-clearcalcs"
                            inheritColor
                        >
                            ClearCalcs vs. StruCalc
                        </Link>
                    </LinksColumn>
                    <LinksColumn>
                        <Paragraph bold>Contact</Paragraph>
                        <Link
                            href="https://app.livestorm.co/clearcalcs/clearcalcs-product-walkthrough?type=detailed&utm_source=homepage&utm_medium=website&utm_campaign=on-demand-demo&utm_term=on-demand-demo&utm_content=on-demand-demo-footer"
                            inheritColor
                        >
                            Product Demo
                        </Link>
                        <Link href="/contact" inheritColor>
                            Contact Us
                        </Link>
                        {LDFlagShowAboutUsPage && (
                            <Link
                                href="https://get.clearcalcs.com/about"
                                inheritColor
                            >
                                About Us
                            </Link>
                        )}
                        <Link
                            href="https://doc.clickup.com/6927027/p/h/6kcnk-4262/95b225f0161ce84"
                            inheritColor
                        >
                            Careers
                        </Link>
                        <Link href="/privacy" inheritColor>
                            Privacy
                        </Link>
                        <Link href="/terms" inheritColor>
                            Terms and Conditions
                        </Link>
                        <Link href="/cookie" inheritColor>
                            Cookies
                        </Link>
                    </LinksColumn>
                </LinksContainer>
                <SocialLinks>
                    <Link
                        href="https://www.youtube.com/channel/UCqxAVCYc_ORPnUmQqELwY6w"
                        inheritColor
                    >
                        <IconYoutube size="24" />
                    </Link>
                    <Link
                        href="https://www.facebook.com/clearcalcs"
                        inheritColor
                    >
                        <IconFacebook size="24" />
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/clearcalcs"
                        inheritColor
                    >
                        <IconLinkedin size="24" />
                    </Link>
                    <Link href="https://twitter.com/clearcalcs" inheritColor>
                        <IconTwitter size="24" />
                    </Link>
                </SocialLinks>
                <Copyright>
                    Copyright © 2023 ClearCalcs Pty. Ltd. ABN 42 614 037 400
                </Copyright>
            </ContentWrapper>
        </Root>
    );
}

const Root = styled.footer`
    background-color: ${blue700};
    color: white;
`;

const ContentWrapper = styled.div`
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    padding: 40px 20px;
`;

const LogoWrapper = styled.div`
    margin-bottom: 40px;
`;
const Logo = styled(LogoSvg)`
    height: 2em;
`;

const LinksColumn = styled.div`
    margin-bottom: 30px;

    > * {
        display: block;
        margin-bottom: 30px;
    }
`;
const LinksContainer = styled.nav`
    display: flex;
    justify-content: space-between;

    ${homepageMenuTablet`
        flex-wrap: wrap;

        & > ${LinksColumn} {
            // Accommodate split into two columns for tablet viewport
            flex-basis: calc(50% - 60px); 
        }
    `}

    ${mobile`
        flex-direction: column;
    `}
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 30px;

    ${landscapeTablet`
        position: absolute;
        right: 20px;
        bottom: 40px;
    `}
`;
const Copyright = styled.p`
    margin-top: 20px;
    font-size: 0.75em;
`;
