import React from "react";
import styled from "styled-components";

import { blue400, grey100 } from "ui/colors";
import { homepageMenuTablet, desktop } from "uiKit/theme/mediaQueries";

type Props = {
    noMargin?: boolean;
    children: any;
};

export default function ListItemContainer(props: Props) {
    return <Container noMargin={props.noMargin}>{props.children}</Container>;
}

const Container = styled.div<{ noMargin?: boolean }>`
    // Allows grey hover to cover full height of parent link
    height: 100%;
    padding: 1.25em;
    border-radius: 10px;

    & > :first-child {
        margin-right: 0.5em;
        color: ${blue400};
    }

    & > :last-child {
        margin-top: 10px;
    }
    ${desktop`
        &:hover {
            background-color: ${grey100};
       }
    `}

    ${homepageMenuTablet`
        // margin-bottom toggle for managing the display: block of DropdownList
        // used in each of the dropdown menus
        margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "36px")};
        padding: 0;
    `}
`;
