import React from "react";
import styled from "styled-components";

import { Link, Text, Paragraph } from "ui";
import { homepageMenuTablet } from "uiKit/theme/mediaQueries";

import {
    IconChalkboardTeacher,
    IconBriefcase,
    IconPen,
    IconVideo,
    IconHandsHelping,
    IconUsers,
} from "ui/icons";

import ListItemContainer from "./ListItemContainer";

export default function LearnMenu() {
    return (
        <DropdownList>
            <Link href="/webinars" inheritColor suppressHoverState>
                <ListItemContainer>
                    <IconChalkboardTeacher />
                    <Text bold>Webinars</Text>
                    <Paragraph>
                        Join our resident structural experts to learn how to do
                        your best work with confidence.
                    </Paragraph>
                </ListItemContainer>
            </Link>
            <Link href="/customers" inheritColor suppressHoverState>
                <ListItemContainer>
                    <IconBriefcase />
                    <Text bold>Case Studies</Text>
                    <Paragraph>
                        Why engineers, architects and building designers depend
                        on ClearCalcs to deliver their best work anywhere,
                        anytime.
                    </Paragraph>
                </ListItemContainer>
            </Link>
            <Link href="/blog" inheritColor suppressHoverState>
                <ListItemContainer>
                    <IconPen />
                    <Text bold>Blog</Text>
                    <Paragraph>The latest ClearCalcs news.</Paragraph>
                </ListItemContainer>
            </Link>
            <Link href="/videos" inheritColor suppressHoverState>
                <ListItemContainer>
                    <IconVideo />
                    <Text bold>Videos</Text>
                    <Paragraph>
                        Watch on-demand videos to learn more about ClearCalcs
                        design & analysis software.
                    </Paragraph>
                </ListItemContainer>
            </Link>
            <Link href="/support" inheritColor suppressHoverState>
                <ListItemContainer>
                    <IconHandsHelping />
                    <Text bold>Support and Learning</Text>
                    <Paragraph>
                        Access our library of support articles to get started
                        using ClearCalcs and learn advanced tricks to manage
                        your projects and calculations.
                    </Paragraph>
                </ListItemContainer>
            </Link>
            <Link
                href="https://blueprint.clearcalcs.com/?utm_source=header-menu&utm_medium=website&utm_campaign=blueprint-signup&utm_term=blueprint"
                inheritColor
                suppressHoverState
            >
                <ListItemContainer noMargin>
                    <IconUsers />
                    <Text bold>Community</Text>
                    <Paragraph>
                        Connect with peers and the ClearCalcs team, share
                        insights, and expand your knowledge in the Blueprint
                        community.
                    </Paragraph>
                </ListItemContainer>
            </Link>
        </DropdownList>
    );
}

const DropdownList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & > * {
        width: calc(33.3% - 15px);
    }

    ${homepageMenuTablet`
        display: block;
    `}
`;
