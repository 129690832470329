import React from "react";
import styled from "styled-components";

import { grey300, yellow400 } from "ui/colors";
import { Link, Text, Paragraph } from "ui";
import { homepageMenuTablet } from "uiKit/theme/mediaQueries";
import { IconCalculator, IconTools, IconGlasses, IconBook } from "ui/icons";

import ListItemContainer from "../components/ListItemContainer";

export default function ProductMenu() {
    return (
        <div>
            <DropdownList>
                <Link href="/calculations" inheritColor suppressHoverState>
                    <ListItemContainer>
                        <IconCalculator />
                        <Text bold>Calculations</Text>
                        <Paragraph>
                            Our regularly updated structural calculation library
                            for wood, steel, concrete and masonry design.
                        </Paragraph>
                    </ListItemContainer>
                </Link>
                <Link href="/docs" inheritColor suppressHoverState>
                    <ListItemContainer>
                        <IconBook />
                        <Text bold>API</Text>
                        <Paragraph>
                            Discover our powerful and flexible API, designed to
                            seamlessly integrate calculators into your
                            workflows.
                        </Paragraph>
                    </ListItemContainer>
                </Link>
                <Link href="/freetools" inheritColor suppressHoverState>
                    <ListItemContainer>
                        <IconTools />
                        <Text bold>Free Tools</Text>
                        <Paragraph>
                            Free, easy to use engineering tools to make your
                            life easier.
                        </Paragraph>
                    </ListItemContainer>
                </Link>
                <Link href="/features" inheritColor suppressHoverState>
                    <ListItemContainer>
                        <IconGlasses />
                        <Text bold>Why ClearCalcs?</Text>
                        <Paragraph>
                            Thousands of engineers have used ClearCalcs to make
                            design calculations the easiest part of their jobs.
                            Find out why.
                        </Paragraph>
                    </ListItemContainer>
                </Link>
            </DropdownList>
            <BookDemo>
                <Paragraph bold>
                    Want to learn how to use specific calculators for your
                    projects?
                </Paragraph>
                <Paragraph>
                    Check out our guided tour on how ClearCalcs will help you
                    save time and be more efficient in your design workflow!
                </Paragraph>
                <BookDemoLink href="https://app.livestorm.co/clearcalcs/clearcalcs-product-walkthrough?type=detailed&utm_source=homepage&utm_medium=website&utm_campaign=on-demand-demo&utm_term=on-demand-demo&utm_content=on-demand-demo-product-menu">
                    <Text bold>Watch a demo</Text>
                </BookDemoLink>
            </BookDemo>
        </div>
    );
}

const DropdownList = styled.div`
    display: flex;
    gap: 20px;

    & > * {
        flex: 1;
    }

    ${homepageMenuTablet`
        display: block;
    `}
`;

const BookDemo = styled.div`
    max-width: 550px;
    margin-top: 20px;
    padding: 1.25em;
    border: 1px solid ${grey300};
    border-radius: 10px;
`;

const BookDemoLink = styled(Link)`
    display: inline-block;
    margin-top: 1em;
    padding: 0.75em 1.25em;
    border-radius: 10px;
    color: black;
    background-color: ${yellow400};
`;
