import React from "react";
import styled from "styled-components";

const SVGRoot = styled.svg`
    fill: currentColor;
    path: currentColor;
`;
type Props = {
    className?: string;
};

const LogoSvg = ({ className }: Props) => (
    <SVGRoot className={className} viewBox="0 0 520 100">
        <path d="M147.43,80c-5.64,0-10.5-1.24-14.58-3.73-4.08-2.49-7.24-5.96-9.49-10.41-2.25-4.45-3.37-9.63-3.37-15.53s1.12-11,3.37-15.45c2.24-4.45,5.41-7.94,9.49-10.45,4.08-2.51,8.94-3.77,14.58-3.77,6.57,0,11.92,1.57,16.06,4.71,4.13,3.14,6.78,7.53,7.93,13.16h-7.64c-.88-3.55-2.67-6.41-5.38-8.57-2.71-2.16-6.37-3.24-10.97-3.24-4.11,0-7.69,.94-10.76,2.83-3.07,1.88-5.43,4.59-7.1,8.11-1.67,3.52-2.51,7.75-2.51,12.66s.83,9.14,2.51,12.66c1.67,3.52,4.04,6.23,7.1,8.11,3.07,1.89,6.65,2.83,10.76,2.83,4.6,0,8.25-1.05,10.97-3.16,2.71-2.1,4.5-4.9,5.38-8.4h7.64c-1.15,5.52-3.79,9.84-7.93,12.95-4.14,3.12-9.49,4.67-16.06,4.67Z" />
        <path d="M180.94,79.02V20h6.9v59.02h-6.9Z" />
        <path d="M217.49,80c-3.89,0-7.34-.89-10.35-2.66-3.01-1.78-5.38-4.25-7.1-7.42-1.73-3.17-2.59-6.91-2.59-11.23s.85-7.99,2.55-11.19c1.7-3.2,4.07-5.68,7.1-7.46,3.04-1.78,6.56-2.66,10.55-2.66s7.35,.89,10.23,2.66c2.87,1.78,5.08,4.11,6.61,7.01,1.53,2.9,2.3,6.01,2.3,9.34,0,.6-.01,1.2-.04,1.8-.03,.6-.04,1.28-.04,2.05h-32.44c.16,3.11,.89,5.7,2.18,7.75,1.29,2.05,2.9,3.59,4.85,4.63,1.94,1.04,4.01,1.56,6.2,1.56,2.85,0,5.23-.66,7.15-1.97,1.92-1.31,3.31-3.09,4.19-5.33h6.82c-1.1,3.77-3.19,6.9-6.28,9.38-3.09,2.49-7.05,3.73-11.87,3.73Zm0-36.8c-3.29,0-6.2,1-8.75,2.99-2.55,2-4.01,4.91-4.39,8.73h25.63c-.16-3.66-1.42-6.53-3.78-8.61-2.36-2.08-5.26-3.11-8.71-3.11Z" />
        <path d="M259.47,80c-3.4,0-6.22-.57-8.46-1.72-2.25-1.15-3.92-2.68-5.01-4.59-1.1-1.91-1.64-3.99-1.64-6.23,0-4.15,1.59-7.35,4.76-9.59,3.17-2.24,7.5-3.36,12.98-3.36h11.01v-.49c0-3.55-.93-6.24-2.79-8.07-1.86-1.83-4.35-2.75-7.47-2.75-2.68,0-5,.67-6.94,2.01-1.94,1.34-3.16,3.29-3.66,5.86h-7.06c.27-2.95,1.27-5.44,3-7.46,1.73-2.02,3.87-3.57,6.45-4.63,2.57-1.07,5.31-1.6,8.21-1.6,5.69,0,9.98,1.52,12.85,4.55,2.88,3.03,4.31,7.06,4.31,12.09v25h-6.16l-.41-7.3c-1.15,2.3-2.83,4.25-5.05,5.86s-5.19,2.42-8.91,2.42Zm1.07-5.82c2.63,0,4.89-.68,6.78-2.05,1.89-1.37,3.33-3.14,4.31-5.33,.99-2.19,1.48-4.48,1.48-6.89v-.08h-10.43c-4.05,0-6.91,.7-8.58,2.09-1.67,1.39-2.51,3.13-2.51,5.21s.78,3.84,2.34,5.12c1.56,1.28,3.76,1.93,6.61,1.93Z" />
        <path d="M290.35,79.02V38.36h6.24l.58,7.79c1.26-2.68,3.17-4.81,5.75-6.39,2.57-1.58,5.75-2.38,9.53-2.38v7.21h-1.89c-2.41,0-4.63,.42-6.65,1.27-2.03,.85-3.64,2.28-4.85,4.3-1.21,2.02-1.81,4.81-1.81,8.36v20.49h-6.9Z" />
        <path d="M345.79,80c-5.64,0-10.5-1.24-14.58-3.73-4.08-2.49-7.24-5.96-9.49-10.41-2.25-4.45-3.37-9.63-3.37-15.53s1.12-11,3.37-15.45c2.24-4.45,5.41-7.94,9.49-10.45,4.08-2.51,8.94-3.77,14.58-3.77,6.57,0,11.92,1.57,16.06,4.71,4.13,3.14,6.78,7.53,7.93,13.16h-7.64c-.88-3.55-2.67-6.41-5.38-8.57-2.71-2.16-6.37-3.24-10.97-3.24-4.11,0-7.69,.94-10.76,2.83-3.07,1.88-5.43,4.59-7.1,8.11-1.67,3.52-2.51,7.75-2.51,12.66s.83,9.14,2.51,12.66c1.67,3.52,4.04,6.23,7.1,8.11,3.07,1.89,6.65,2.83,10.76,2.83,4.6,0,8.25-1.05,10.97-3.16,2.71-2.1,4.5-4.9,5.38-8.4h7.64c-1.15,5.52-3.79,9.84-7.93,12.95-4.14,3.12-9.49,4.67-16.06,4.67Z" />
        <path d="M392.85,80c-3.4,0-6.22-.57-8.46-1.72-2.25-1.15-3.92-2.68-5.01-4.59-1.1-1.91-1.64-3.99-1.64-6.23,0-4.15,1.59-7.35,4.76-9.59,3.17-2.24,7.5-3.36,12.98-3.36h11.01v-.49c0-3.55-.93-6.24-2.79-8.07-1.86-1.83-4.35-2.75-7.47-2.75-2.68,0-5,.67-6.94,2.01-1.94,1.34-3.16,3.29-3.66,5.86h-7.06c.27-2.95,1.27-5.44,3-7.46,1.73-2.02,3.87-3.57,6.45-4.63,2.57-1.07,5.31-1.6,8.21-1.6,5.69,0,9.98,1.52,12.85,4.55,2.88,3.03,4.31,7.06,4.31,12.09v25h-6.16l-.41-7.3c-1.15,2.3-2.83,4.25-5.05,5.86s-5.19,2.42-8.91,2.42Zm1.07-5.82c2.63,0,4.89-.68,6.78-2.05,1.89-1.37,3.33-3.14,4.31-5.33,.99-2.19,1.48-4.48,1.48-6.89v-.08h-10.43c-4.05,0-6.91,.7-8.58,2.09-1.67,1.39-2.51,3.13-2.51,5.21s.78,3.84,2.34,5.12c1.56,1.28,3.76,1.93,6.61,1.93Z" />
        <path d="M423.74,79.02V20h6.9v59.02h-6.9Z" />
        <path d="M460.7,80c-3.89,0-7.38-.89-10.47-2.66-3.09-1.78-5.53-4.26-7.31-7.46-1.78-3.2-2.67-6.93-2.67-11.19s.89-7.99,2.67-11.19c1.78-3.2,4.22-5.68,7.31-7.46,3.09-1.78,6.58-2.66,10.47-2.66,4.82,0,8.88,1.26,12.2,3.77,3.31,2.51,5.41,5.88,6.28,10.08h-7.06c-.55-2.51-1.89-4.47-4.02-5.86-2.14-1.39-4.63-2.09-7.47-2.09-2.3,0-4.46,.57-6.49,1.72-2.03,1.15-3.67,2.87-4.93,5.16-1.26,2.3-1.89,5.14-1.89,8.52s.63,6.23,1.89,8.53c1.26,2.3,2.9,4.03,4.93,5.2,2.03,1.18,4.19,1.76,6.49,1.76,2.85,0,5.34-.7,7.47-2.09,2.14-1.39,3.48-3.37,4.02-5.94h7.06c-.82,4.1-2.9,7.43-6.24,10-3.34,2.57-7.42,3.85-12.24,3.85Z" />
        <path d="M504.39,80c-4.87,0-8.93-1.23-12.16-3.69-3.23-2.46-5.12-5.79-5.67-10h7.06c.44,2.13,1.57,3.98,3.41,5.53,1.83,1.56,4.31,2.34,7.43,2.34,2.9,0,5.04-.61,6.41-1.84,1.37-1.23,2.05-2.69,2.05-4.38,0-2.46-.89-4.1-2.67-4.92-1.78-.82-4.28-1.56-7.52-2.21-2.19-.44-4.38-1.07-6.57-1.88-2.19-.82-4.02-1.98-5.5-3.48-1.48-1.5-2.22-3.48-2.22-5.94,0-3.55,1.33-6.46,3.98-8.73,2.66-2.27,6.26-3.4,10.8-3.4s7.87,1.08,10.64,3.24c2.76,2.16,4.37,5.23,4.8,9.22h-6.82c-.27-2.08-1.16-3.7-2.67-4.88-1.51-1.17-3.52-1.76-6.04-1.76s-4.37,.52-5.71,1.56c-1.34,1.04-2.01,2.41-2.01,4.1s.86,2.92,2.59,3.85c1.72,.93,4.07,1.72,7.02,2.38,2.52,.55,4.91,1.22,7.19,2.01,2.27,.79,4.13,1.97,5.59,3.52,1.45,1.56,2.18,3.81,2.18,6.76,.05,3.66-1.33,6.68-4.15,9.06-2.82,2.38-6.64,3.57-11.46,3.57Z" />

        <g>
            <path d="M85.36,14.64C75.92,5.2,63.36,0,50,0S24.08,5.2,14.64,14.64C5.2,24.08,0,36.64,0,50s5.2,25.92,14.64,35.36c9.44,9.44,22,14.64,35.36,14.64s25.92-5.2,35.36-14.64c9.44-9.44,14.64-22,14.64-35.36s-5.2-25.92-14.64-35.36Zm9.07,35.36c0,24.5-19.93,44.43-44.43,44.43-11.85,0-22.98-4.63-31.35-13.02-8.36-8.39-12.96-19.54-12.96-31.41C5.69,25.5,25.57,5.57,50,5.57s44.43,19.93,44.43,44.43Z" />

            <path d="M77.96,43.68l-12.34-10.34c-1.01-.9-1.42-1.63-1.42-2.54,0-1.17,1.48-1.77,4.39-1.77h9.21v-5.44h-9.33c-3.43,0-5.93,.56-7.89,1.78-1.64,1.51-2.44,3.28-2.44,5.43s.98,4.22,2.83,5.7l3.74,3.18H31.91c-4.2,0-7.81,1.69-11.04,5.17-3.17,3.3-4.78,7.39-4.78,12.14s1.61,8.71,4.91,12.14c3.35,3.48,6.96,5.17,11.04,5.17s7.61-1.74,10.79-5.17c3.17-3.3,4.78-7.39,4.78-12.14s-1.53-8.55-4.67-12h14.14c-.16,.18-.3,.35-.44,.53-2.81,3.61-4.12,7.22-4.12,11.35,0,4.46,1.65,8.5,4.9,12.01,3.18,3.44,6.86,5.18,10.92,5.18s7.61-1.74,10.79-5.17c3.13-3.25,4.78-7.41,4.78-12.02,0-5.44-2-9.87-5.95-13.18Zm-2.57,21.75l-.02,.02c-1.99,2.34-4.36,3.53-7.03,3.53-2.51,0-4.74-1.12-7.03-3.53-2.09-2.46-3.1-5.23-3.1-8.47,0-3.49,1.02-6.27,3.11-8.48,1.89-2.37,4.19-3.52,7.02-3.52,2.67,0,5.04,1.19,7.03,3.53,2.09,2.45,3.1,5.22,3.1,8.47,0,3.5-1.01,6.26-3.08,8.45ZM24.88,48.52c1.96-2.31,4.39-3.53,7.03-3.53s4.86,1.13,7.04,3.54c2.16,2.4,3.21,5.17,3.21,8.45,0,3.1-1.09,5.95-3.23,8.47-1.99,2.34-4.35,3.53-7.03,3.53-2.51,0-4.75-1.13-7.04-3.54-2.16-2.4-3.21-5.17-3.21-8.45s1.08-6.09,3.23-8.47Z" />
        </g>
    </SVGRoot>
);

export default LogoSvg;
